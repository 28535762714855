<template>
  <customSection :title="title" :overflow="true">
    <ul class="boxsList">
      <li v-for="(item, key) in boxData" :key="key">
        <label v-text="item.text"></label>
        <div class="detail">
          <span class="box">电箱 {{ item.box }}</span>
          <span class="camera">摄像头 {{ item.camera }}</span>
        </div>
      </li>
    </ul>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";
import {Back} from "@element-plus/icons-vue";

export default {
  data() {
    return {
      boxData: [{
        text: '1号楼',
        box: 2,
        camera: 10
      }, {
        text: '2号楼',
        box: 2,
        camera: 10
      }, {
        text: '3号楼',
        box: 2,
        camera: 10
      }, {
        text: '4号楼',
        box: 2,
        camera: 10
      }, {
        text: '5号楼',
        box: 2,
        camera: 10
      }, {
        text: '6号楼',
        box: 2,
        camera: 10
      }]
    }
  },
  components: {Back, customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let that = this;
    that.init();
  },
  computed: {},
  methods: {
    init() {
      let that = this;

    }
  }
}
</script>
<style scoped lang="stylus">

.boxsList
  display flex
  padding 0 10px
  flex-direction row
  flex-wrap wrap
  list-style none

  li
    width 50%
    min-width 180px
    padding 10px
    display flex
    flex-direction row

    label
      position relative
      font-size 15px
      color #fff
      width 70px
      height 70px
      line-height @height
      text-align center
      white-space nowrap

      &:before
        position absolute
        content ''
        left 0
        top 0
        width 162px
        height 100%
        background url(@/assets/images/box-bg.png) left center no-repeat
        background-size 100% 100%

    .detail
      flex 1
      padding-left 10px
      display flex
      flex-direction column
      font-size 15px
      padding-top 10px
      font-size 13px
      color #ffffff
      line-height 30px

      .camera
      .box
        background-position left center
        background-repeat no-repeat
        background-size auto 15px
        text-indent 20px
        text-align left
        white-space nowrap

      .box
        background-image url(@/assets/images/box-box.png)

      .camera
        background-image url(@/assets/images/box-camera.png)
.boxsList li[data-v-05aa40d9] {
  margin-top:28px !important;
}
</style>