<template>
  <customModal ref="popList" size="lg" type="center" :mask-close="true" title="巡视记录">
    <el-form :inline="true" class="searchForm">
      <el-form-item size="small">
        <el-input v-model="search" placeholder="请输入监控位置"/>
        <el-button class="btn-search" @click="onSearch">
          <el-icon>
            <Search/>
          </el-icon>
        </el-button>
      </el-form-item>
    </el-form>
    <div class="tablewrapper">
      <el-table :data="tableData" stripe size="large" class="dataTable" height="60vh" style="width: 100%">
        <el-table-column label="图片" width="120">
          <div style="display: flex; align-items: center">
            <el-image :src="imgSrc" :hide-on-click-modal="true" :lazy="true" :preview-src-list="srcList" :preview-teleported="true"/>
          </div>
        </el-table-column>
        <el-table-column prop="name" label="项目名称"/>
        <el-table-column prop="subname" label="分行名称"/>
        <el-table-column prop="statusText" align="center" label="项目状态" show-overflow-tooltip/>
        <el-table-column prop="day" align="center" label="节点停留天数" show-overflow-tooltip/>
      </el-table>
    </div>
    <el-pagination class="pagination"
                   hide-on-single-page="1"
                   v-model:current-page="currentPage"
                   v-model:page-size="pageSize"
                   :page-sizes="pageSizes"
                   :size="pageSize"
                   :disabled="false"
                   :background="false"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="400"
                   :small="true"
                   @size-change="handleSizeChange"
                   @current-change="loadList"
    />
  </customModal>
</template>
<script>
import customModal from "@/components/customModal/customModal.vue";

export default {
  data() {
    return {
      search: '',
      pageSizes: [20, 50, 100, 200],
      pageSize: 20,
      currentPage: 1,
      tableData: [],
      imgSrc: 'https://zhgd.zdyzz.com/assets/img/index-overview1.jpg',
      srcList: ['https://zhgd.zdyzz.com/assets/img/index-overview1.jpg']
    }
  },
  components: {
    customModal,
  },

  mounted() {
    let that = this;
    that.init();
  },
  computed: {},
  methods: {
    onSearch() {
      let that = this;
      console.log('====doSearch====', 1);
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      that.loadList(1)
    },
    loadList(page = 1) {
      let that = this;

    },
    init() {
      let that = this;
      that.tableData = [
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
      ];
      that.$refs.popList.init();
    }
  }
}
</script>
<style scoped lang="stylus">
.pagination
  margin 0 20px 20px 20px
  justify-content center
  overflow hidden

  :deep(.el-pager li)
    border 1px solid #5ca9ff
    border-radius 5px
    color #5ca9ff
    margin 0 2px

    &.is-active
      background-color #5ca9ff
      color #fff

  :deep(.el-pagination__jump)
  :deep(.el-pagination__total)
  :deep(.btn-prev)
  :deep(.btn-next)
    color #5ca9ff

  :deep(.el-select__wrapper)
  :deep(.el-input__wrapper)
    box-shadow none
    border 1px solid #5ca9ff

    input
    .el-select__selected-item
      color #5ca9ff

  :deep(.el-input__wrapper)
    background none

.searchForm
  padding 20px 40px 0 40px
  text-align left

  :deep(.el-form-item__content)
    position relative

  :deep(.el-input__wrapper)
    box-shadow none
    border 1px solid #5ca9ff
    background-color rgba(0, 133, 255, 0.2)
    padding-right 30px

    input
      color #fff

  .btn-search
    background none
    border none
    position absolute
    right 0
    top 50%
    transform translate(0, -50%)
    z-index 10

.tablewrapper
  padding 0 40px 20px 40px

  :deep(.el-table__row)
    background-color #061d3d !important

  :deep(.el-table__row--striped)
    background #163e6f !important

  :deep(.el-table__cell)
    background-color transparent !important

  :deep(.el-table thead tr)
    background-image linear-gradient(to bottom, #2e92d2, #013074)

  :deep(.el-table thead th)
    font-size 16px
    color #fff !important
    font-weight 600 !important
    border none !important

  :deep(.el-table)
    tr
      td
        border-color rgba(255, 255, 255, 0, 1) !important

      &:hover
        td
          background-color rgba(0, 0, 0, 0.4) !important

  :deep(.el-table__inner-wrapper::before)
    background-color transparent !important
</style>