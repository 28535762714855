<template>
  <customPopup ref="popup" type="center" :mask-close="true">
    <div class="modal" :class="size">
      <div class="title">
        {{ title }}
        <el-icon class="icon" @click.stop.prevent="$refs.popup.close"><Close /></el-icon>
      </div>
      <div class="content">
        <slot />   
      </div>
    </div>
  </customPopup>
</template>
<script>
import customPopup from "@/components/customPopup/customPopup";

export default {
  data() {
    return {}
  },
  components: {
    customPopup,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'lg'
    }
  },
  mounted() {
    let that = this;
    that.init();
  },
  computed: {},
  methods: {
    init() {
      let that = this;
      that.$refs.popup.open();
    }
  }
}
</script>
<style scoped lang="stylus">
.modal
  max-width 90vw
  min-height 50vh
  border-left 1px solid
  border-right 1px solid
  border-image linear-gradient(to bottom, #5ca9ff 0%, rgba(0, 133, 255, 0.1) 50%, #5ca9ff 100%) 1;
  border-bottom 1px solid #5ca9ff
  background-color rgba(0, 133, 255, 0.2)
  background-position left bottom, right bottom
  background-repeat no-repeat
  background-size 40px 40px, 40px 40px

  .title
    background-size 100% 100%
    background-position center top
    background-repeat no-repeat
    position relative
    color #fff
    font-family 'dinBold'
    font-size 24px
    line-height 65px
    text-shadow 0 1px 2px #006fe7
    height @line-height
    .icon
      position absolute
      right 20px
      top 50%
      font-size 40px
      line-height 65px
      color #fff
      cursor pointer
      transform translateY(-50%)
  @media (max-width 767px)
    width 90vw

  &.lg
    width 90vw
    background-image url(@/assets/images/popup-bottom-left-lg.png) , url(@/assets/images/popup-bottom-right-lg.png)

    .title
      background-image url(@/assets/images/modal-title-lg.png)

  &.sm
    min-width 1024px
    background-image url(@/assets/images/popup-bottom-left-sm.png) , url(@/assets/images/popup-bottom-right-sm.png)
    @media (max-width 1024px)
      width 90vw

    .title
      background-image url(@/assets/images/modal-title-sm.png)
</style>