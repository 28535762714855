<template>
  <customModal ref="popupDetail" size="sm" type="center" :mask-close="true" title="项目详情">
    <div class="projectPage_status" style="height: 100%">
      <div class="projectPage_status_num">
<!--        style="margin-top: 76px"-->
        <div v-for="(item,index) in recordList" :key="index" :style="index%2==0 ? 'margin-top: 76px' :'margin-top: 0px'">
          <div>{{item.codeName}}</div>
          <div>{{item.createTime}}</div>
        </div>
<!--        <div>-->
<!--          <div>节点名称</div>-->
<!--          <div>2022-10-01</div>-->
<!--        </div>-->
        <div style="position: absolute;top:0;left: 0;width: 100%;">
          <div class="projectPage_status_line"></div>
          <div v-for="(item,index) in recordList" :key="index" :style="{left:index*163 +'px'}" class="projectPage_drop"></div>
<!--          <div class="projectPage_drop_two"></div>-->
        </div>
      </div>
    </div>
    <div class="projectPage">
      <div class="projectPage_left">
        <div>
          <span class="projectPage_title">项目名称</span>
          <span class="projectPage_title_con">{{recordListDetailData.name}}</span>
        </div>
        <div>
          <span class="projectPage_title">项目属性</span>
          <span class="projectPage_title_con">{{recordListDetailData.type == '1' ? '重点项目' : '普通项目'}}</span>
          <img src="" alt="">
        </div>
        <div>
          <span class="projectPage_title">立项日期</span>
          <span class="projectPage_title_con">{{recordListDetailData.initiationDate}}</span>
        </div>
        <div>
          <span class="projectPage_title">项目状态</span>
          <span class="projectPage_state">{{recordListDetailData.projectStatus}}</span>
        </div>
        <div>
          <span class="projectPage_title">合同金额</span>
          <span class="projectPage_title_con">{{recordListDetailData.amount}}</span>
        </div>
      </div>
      <div class="projectPage_right">
        <div>
          <span class="projectPage_title">项目编号</span>
          <span class="projectPage_title_con">{{recordListDetailData.projectNo}}</span>
        </div>
        <div>
          <span class="projectPage_title">项目位置</span>
          <span class="projectPage_title_con">{{recordListDetailData.projectAddress}}</span>
          <img src="" alt="">
        </div>
        <div>
          <span class="projectPage_title">项目负责人</span>
          <span class="projectPage_title_con">{{recordListDetailData.projectManager}}</span>
        </div>
        <div>
          <span class="projectPage_title">建设规模</span>
          <span class="projectPage_title_con">{{recordListDetailData.constructionScale}}</span>
        </div>
      </div>
    </div>

    <div class="projectPage_time">
      <div class="projectPage_left">
        <div class="projectPage_time_type">
          计划时间
        </div>
        <div>
          <span class="projectPage_title">计划开工时间</span>
          <span class="projectPage_title_con">2024-06-02</span>
        </div>
        <div>
          <span class="projectPage_title">计划竣工时间</span>
          <span class="projectPage_title_con">2026-06-02</span>
          <img src="" alt="">
        </div>
        <div>
          <span class="projectPage_title">计划总工期</span>
          <span class="projectPage_title_con">2年</span>
        </div>
      </div>
      <div class="projectPage_right">
        <div class="projectPage_time_type">
          实际时间
        </div>
        <div>
          <span class="projectPage_title">实际开工时间</span>
          <span class="projectPage_title_con">2024-06-02</span>
        </div>
        <div>
          <span class="projectPage_title">实际竣工时间</span>
          <span class="projectPage_title_con">2026-06-02</span>
          <img src="" alt="">
        </div>
        <div>
          <span class="projectPage_title">实际总工期</span>
          <span class="projectPage_title_con">2年</span>
        </div>
      </div>
    </div>
    <div class="projectPage_img">
      <div class="projectPage_pic">
        <span class="projectPage_title">项目照片</span>
        <img :src="recordListDetailData.pic" alt="">
      </div>
      <div class="projectPage_pic">
        <div style="width: 320px" class="projectPage_title">项目说明</div>
        <div class="projectPage_details">
          {{recordListDetailData.projectNote}}
        </div>
      </div>
    </div>
    <div class="projectPage">
      <div class="projectPage_left">
        <div>
          <span class="projectPage_title">创建人</span>
          <span class="projectPage_title_con_two">{{recordListDetailData.createBy}}</span>
        </div>
        <div>
          <span class="projectPage_title">修改人</span>
          <span class="projectPage_title_con_two">{{recordListDetailData.updateBy}}</span>

        </div>
      </div>
      <div class="projectPage_right">
        <div>
          <span class="projectPage_title">创建时间</span>
          <span class="projectPage_title_con_two">{{recordListDetailData.createTime}}</span>
        </div>
        <div>
          <span class="projectPage_title">修改时间</span>
          <span class="projectPage_title_con_two">{{recordListDetailData.updateTime}}</span>
        </div>
      </div>

    </div>
    <div style="margin: 12px 90px 120px 90px">
      <div class="projectPage_pic">
        <div style="width: 320px" class="projectPage_title">安全文件描述</div>
        <div class="projectPage_details">
          {{recordListDetailData.safeFileDesc}}
        </div>
      </div>
    </div>
  </customModal>
</template>
<script>
import customModal from "@/components/customModal/customModal.vue";
import axios from "axios";
import Qs from "qs";

export default {
  props: {
    recordList: {
      type: Array,
    },
    recordListDetailData:{
      type: Object,
    }
  },
  data() {
    return {

    }
  },
  components: {
    customModal,
  },

  mounted() {
    console.log(this.recordListDetailData,'详细信息')
    let that = this;

    that.init();
  },
  computed: {},
  methods: {
    init() {
      let that = this;
      that.$refs.popupDetail.init();
    },

  }
}
</script>
<style scoped lang="stylus">
.projectPage {
  margin: 52px 90px 0 90px;
  display: flex;
  justify-content: space-between;
}
.projectPage_left {
  text-align: left;
}
.projectPage_title {
  font-size: 16px;
  color: #B6D3E6;
  line-height: 22px;
  margin-top: 12px;
}
.projectPage_title_con {
  margin-left: 44px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
}
.projectPage_state {
  font-size: 16px;
  color: #1CE2A7;
  line-height: 22px;
  margin-left: 44px;
}
.projectPage_right {
  text-align: left;
  width: 50%;
}
.projectPage_time {
  margin: 36px 90px 0 90px;
  display: flex;
  justify-content: space-between;
}
.projectPage_time_type {
  font-size: 16px;
  color: #FFFFFF;
}
.projectPage_img {
  margin: 64px 90px 0 90px;
}
.projectPage_pic {
  text-align: left;
  display: flex;
}
.projectPage_details {
  display: flex;
  flex-wrap: wrap;
}
.projectPage_title_con_two {
  margin-left: 76px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 22px;
}
.projectPage_status {
  margin: 72px 90px 64px 90px;
  display: flex;
}
.projectPage_status_num {
  position: relative;
  display: flex;
}
.projectPage_status_line {
  width: 100%;
  height: 1px;
  border: 1px solid #7C7C7C;
  margin-top: 60px;
}
.projectPage_drop {
  position: absolute;
  top:51px;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border-radius: 9px;
  border: 2px solid #00DE96;
}
.projectPage_drop_two{
  position: absolute;
  top:51px;
  left: 163px;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border-radius: 9px;
  border: 2px solid #00DE96;
}
</style>