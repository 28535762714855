/**
 * 格式化时间字符串
 * @param datetime
 * @param fmt
 * @returns {string}
 */
export const formatDatetime = function (datetime, fmt = 'yyyy-MM-dd hh:mm:ss') {
    if (String(datetime).indexOf("-") > -1) {
        datetime = datetime.replace('T', ' ').replace('.000+08:00', '').replace(/-/g, '/')
    }
    const date = new Date(datetime)
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            const str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
        }
    }
    return fmt;
}