<template>
  <customSection :title="title" :overflow="false">
    <div class="echart3dBar" ref="echart3dBar"></div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";
import axios from 'axios'
import Qs from 'qs'

export default {
  data() {
    return {
      xData:[],
      yData:[]
    }
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  async mounted() {
    let that = this;
    await that.echart3dBar().then(res=>{
      console.log(res.data,'接口数据')
      //this.chinaData = res.data;
      for(let i = 0; i<res.data.length;i++){
        that.xData.push(res.data[i].name)
        that.yData.push(res.data[i].value)
      }
    })
    that.init();
  },
  computed: {},
  methods: {
    async echart3dBar (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/statistics/project/quantityProCity',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002'
        })
      })
      return res
    },
    init() {
      let that = this;
      let chart = echarts.init(that.$refs.echart3dBar, "dark");
      const industryColor = {
        type: 'linear',
        x: 1,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: '#30CCC2'
          },
          {
            offset: 1,
            color: 'rgba(9, 33, 63, 0.5)'
          }
        ]
      };

      const industryData = this.yData
      var option = {
        backgroundColor: 'transparent',
        animation: false,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '0%',
          right: '4%',
          bottom: '0%',
          top: '10px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xData,
          axisLabel: {
            color: '#E6F7FF'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255, 255, 2555, 0.1)'
            }
          },
          axisTick: {
            show: false
          },
          animation: false
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#E6F7FF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(255,255,255,0.2)'
            }
          },
          animation: false
        },
        series: [
          {
            type: 'bar',
            barWidth: 11,
            data: industryData,
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      //color: '#30CCC2'
                      color: '#16CCFB'
                    },
                    {
                      offset: 0.8,
                      color:'#0A3770'
                      //color: 'rgba(9, 33, 63, 0.2)'
                    }
                  ]
                }
              }
            },
            tooltip: {
              show: false
            }
          },
          {
            tooltip: {
              show: true,
              padding: 100,
              position: [-10, '50%'],
              borderWidth: 100
            },
            type: 'bar',
            barWidth: 11,
            barCategoryGap: '60%',
            itemStyle: {
              //柱子右面
              normal: {
                // 重要的是color配置：线性渐变， 从上往下
                color: industryColor,
                borderWidth: 0.3,
                borderColor: 'rgba(9, 36, 83, 0.4)'
              }
            },
            data: industryData,
            // 间隔为0
            barGap: 0,
            z: 5
          },
          {
            z: 10,
            type: 'pictorialBar',
            symbolPosition: 'end',
            data: industryData,
            symbol: 'diamond',
            symbolOffset: [0, -12],
            symbolRotate: 90,
            symbolSize: [10.5, 23],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: '#3BEDDE'
              }
            },
            tooltip: {
              show: false
            }
          }
        ]
      };

      chart.setOption(option)
      window.onresize = function () {
        chart.resize();
      };
    }
  }
}
</script>
<style scoped lang="stylus">
.echart3dBar
  display block
  width 100%
  height 20vh
  padding 15px
</style>