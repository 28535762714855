<template>
  <pageHeader/>
  <div class="page">
        <projectDetail v-show="isProjectDetail" :recordListDetailData="recordListDetailData" :recordList="recordListData"/>
<!--    <sectionListLg />-->
    <el-row class="pagerow">
      <el-col :xs="24" :sm="24" :md="7" :lg="6" :xl="6" class="pagecol">
        <sectionEchart3dBar title="分行纬度整改单数量"/>
          <sectionEchartLine title="每月开工项目增量趋势图"/>
          <sectionEchartPie title="项目数量统计图"/>
<!--        <sectionEchartLineBase title="用电量"/>-->
<!--        <sectionEchartPieProgress title="电箱运营数据"/>-->
      </el-col>

      <el-dialog align-center class="ele1" v-model="dialogDetail" title="项目明细">
            <projectInfo />
          </el-dialog>

      <el-col :xs="24" :sm="24" :md="10" :lg="12" :xl="12" class="pagecol bodycol">
        <div class="pageTotals">
          <div class="totalItem" v-for="(item,index) in echartPieData" :key="index">

<!--            <img :src=`${item.iconUrl}` :alt="`${item.iconUrl}`"/>-->
            <img v-if="index == 0" src="@/assets/images/home-total.png" alt="">
            <img v-if="index == 1" src="@/assets/images/home-apply.png" alt="">
            <img v-if="index == 2" src="@/assets/images/home-building.png" alt="">
            <img v-if="index == 3" src="@/assets/images/home-complete.png" alt="">
            <div class="itemText" @click="projectList(index)">
              <strong>{{item.value}}</strong>
              {{item.name}}（个）
            </div>
          </div>
        </div>
        <div style="color: white;position: relative">
          <div class="map_con">
            <div class="map_china" @click="goBack">中国</div>
            <div class="map_shanghai" @click="goBack">上海市</div>
          </div>

<!--          <img src="@/assets/images/box-bg.png" alt="">-->
          <div ref="chinaMap" style="width: 56rem;height: 30rem;"></div>
          <!-- <div class="homeMap">
            <div class="project_title">
              <img src="@/assets/home/keyNoteLogo.png" alt="">
              <span>重点项目</span>
            </div>
            <div class="project_title" style="margin-top: 22px">
              <img src="@/assets/home/genericLogo.png" alt="">
              <span>普通项目</span>
            </div>
          </div> -->
        </div>
        <!-- <div class="projectMenu">
          <router-link to="" class="menuItem">项目信息</router-link>
          <router-link to="/Visualization" class="menuItem">现场可视化</router-link>
          <router-link to="" class="menuItem">智慧电箱</router-link>
          <router-link to="/" class="menuItem" @click="goBackWork">工作台</router-link>
        </div> -->
      </el-col>
      <el-col :xs="24" :sm="24" :md="7" :lg="6" :xl="6" class="pagecol">
<!--        <sectionElectricBox title="电箱概览" />-->
        <sectionKeyProject title="重点项目展示" style="height: 213.59px"/>

        <ordinaryProject title="普通项目展示" style="height: 213.59px"></ordinaryProject>
<!--        <sectionWarningList title="监控报警" />-->
        <sectionTopList title="系统活跃排名" />
      </el-col>
    </el-row>
<!--    <div style="width: 316px;height: 190px;background: #013964">-->
<!--      <div style="display: flex;justify-content: left;margin: 12px 0 10px 16px">-->
<!--        <img style="width: 26px;height: 26px;vertical-align: middle" src="@/assets/home/keyNoteLogo.png" alt="">-->
<!--        <span style="font-size: 16px;color: #ffffff;vertical-align: middle">重点项目</span>-->
<!--      </div>-->
<!--      <div style="width: 100%;border: 1px solid rgba(195,216,225,0.56);"></div>-->
<!--      <div style="display: flex;justify-content: space-between">-->
<!--        <div style="margin: 12px 16px">-->
<!--          <div style="text-align: left">-->
<!--            <span style="font-size: 14px;color: #ffffff">项目名称：</span>-->
<!--            <span style="font-size: 14px;color: #A2B5CA">这是项目名称这是项目名称</span>-->
<!--          </div>-->
<!--          <div style="text-align: left;margin-top: 12px">-->
<!--            <span style="font-size: 14px;color: #ffffff">项目位置：</span>-->
<!--            <span style="font-size: 14px;color: #A2B5CA">这是项目位置这是项目位置这是项</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <img style="width: 100px;height: 100px;margin-top: 12px;margin-right: 16px" src="@/assets/home/profilePicture.png" alt="">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="home_con">-->
<!--      <div class="home_con_title">一般项目</div>-->
<!--      <div class="home_solid"></div>-->
<!--      <div class="homeInfo">-->
<!--        <div class="home_total">-->
<!--          <div style="width: 60%;text-align: left">项目总数</div>-->

<!--          <div class="home_number">-->
<!--            <span>288</span>-->
<!--            <span style="font-size: 14px;color: #ffffff">个</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div style="width:100%;text-align: left">申报工程项目</div>-->
<!--          <div class="home_number">-->
<!--            <span>288</span>-->
<!--            <span style="font-size: 14px;color: #ffffff">个</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="homeInfo">-->
<!--        <div class="home_total">-->
<!--          <div style="width: 60%;text-align: left">在建工程项目</div>-->

<!--          <div class="home_number">-->
<!--            <span>288</span>-->
<!--            <span style="font-size: 14px;color: #ffffff">个</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div style="width:100%;text-align: left">已完成工程项目</div>-->
<!--          <div class="home_number">-->
<!--            <span>288</span>-->
<!--            <span style="font-size: 14px;color: #ffffff">个</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div v-if="isShowProject" class="projectCon">
    </div>
    <div v-if="isShowProject" style="position: absolute;z-index: 9999;width: 100%;height: 100%;margin: 0 auto;text-align: center;margin-left: 160px">
      <div class="projectContain">
        <div class="projectContain_title">
          项目明细
          <span class="project_close">x</span>
        </div>
        <el-form :inline="true" class="searchForm" style="text-align: left;margin-left: 32px">
          <el-form-item size="small">
            <div class="flex gap-4 items-center">
              <el-input
                  style="width: 240px"
                  size="large"
                  placeholder="搜索项目名称"
                  v-model="project.name"
              />
            </div>
          </el-form-item>
          <el-form-item size="small">
            <el-form-item size="small">
              <div class="flex flex-wrap gap-4 items-center">
                <select v-model="project.projectStatus" class="select" placeholder="项目状态">
                  <option  v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">{{item.value}}</option>
                </select>
              </div>
            </el-form-item>
          </el-form-item>
          <el-form-item size="small">
            <el-form-item size="small">
              <button @click="searchList" type="button"  class="searchBtn">查询</button>
            </el-form-item>
          </el-form-item>
        </el-form>
        <div class="tablewrapper">
          <el-table :data="projectListData" stripe size="large" class="homeTable" height="60vh" style="width: 100%">
            <el-table-column prop="projectNo" label="项目序号">
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.projectNo}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="项目名称">
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="companyName" label="分行名称">
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="projectNode" align="center" label="项目节点" show-overflow-tooltip>
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.projectNode}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="projectAddress" align="center" label="项目位置" show-overflow-tooltip>
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.projectAddress}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="projectStatus" align="center" label="项目状态" show-overflow-tooltip>
              <template #default="{row}" >
                <span @click="projectDetailFn(row)" v-if="row.projectStatus == 0">前期</span>
                <span @click="projectDetailFn(row)" v-if="row.projectStatus == 1">在建</span>
                <span @click="projectDetailFn(row)" v-if="row.projectStatus == 2">竣工</span>
                <span @click="projectDetailFn(row)" v-if="row.projectStatus == 3">销项</span>
                <span @click="projectDetailFn(row)" v-if="row.projectStatus == 4">停工</span>
<!--                <span @click="projectDetailFn(row)">{{row.projectStatus}}</span>-->
              </template>
            </el-table-column>
            <el-table-column prop="changeTime" align="center" label="项目节点开始时间" show-overflow-tooltip>
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.changeTime}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="apartDay" align="center" label="项目节点停留时间" show-overflow-tooltip>
              <template #default="{row}" >
                <span @click="projectDetailFn(row)">{{row.apartDay}}</span>
              </template>
            </el-table-column>
            <el-table-column  align="center" label="操作">
              <template #default="{row}">
                <span @click="projectInfo(row)">查看详情</span>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <!-- :page-sizes="pageSizes"-->
        <el-pagination class="pagination"
                       hide-on-single-page="1"
                       v-model:current-page="currentPage"
                       v-model:page-size="pageSize"
                       :size="pageSize"
                       :disabled="false"
                       :background="false"
                       layout="total, prev, pager, next, jumper"
                       :total="project.total"
                       :small="true"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pageHeader from '@/components/header.vue'
import projectDetail from "@/components/projectDetail.vue";
import customSection from "@/components/customSection/customSection.vue";
import sectionEchartLine from "@/components/sectionEchartLine.vue";
import sectionEchart3dBar from "@/components/sectionEchart3dBar.vue";
import sectionEchartPie from "@/components/sectionEchartPie.vue";
import sectionEchartLineBase from "@/components/sectionEchartLineBase.vue";
import sectionEchartPieProgress from "@/components/sectionEchartPieProgress.vue";
import sectionKeyProject from "@/components/sectionKeyProject.vue";
import ordinaryProject from "@/components/ordinaryProject.vue";
import sectionListLg from "@/components/sectionListLg.vue";
import sectionWarningList from "@/components/sectionWarningList.vue";
import sectionTopList from "@/components/sectionTopList.vue";
import sectionElectricBox from "@/components/sectionElectricBox.vue";
import projectInfo from '@/components/projectInfo.vue';
//地图
import chinaMapJson from "@/assets/zhongguo/china.json"
import * as echarts from "echarts";
import axios from 'axios'
import Qs from 'qs'
import { Search } from '@element-plus/icons-vue'
async function getMapJson(name) {
  const jsonData = await import('@/assets/mapJson/'+name+'.json')
  return jsonData.default
}
//用来存放当前的地图名字
  let currentName = ''
//用来存放下钻的历史记录
  let history = []

  async function goDown(name, chart,ordinaryProject,provincialeKeyProject) {
    //获取地图数据之后，修改地图options,chart
    const mapname = name
    if (!echarts.getMap(name)) {
      const newMapJson = await getMapJson(name)
      console.log(name);
      echarts.registerMap(mapname, newMapJson)
      // console.log(mapname, newMapJson, '数据', chinaMapJson)
    }
    var optionsNew = {
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          console.log(params.data,'params值')
          if(params.data) {
            if(params.data.type=='2'){
             
              return `<div style="width: 316px;height: 190px;background:#09325D;border: 1px solid #00cdff;">
                    <div style="margin-left: 12px;line-height: 38px;font-size: 16px;color: #fff;text-align: left;">一般项目</div>
                    <div style="width: 316px;height: 1px;border: 1px solid #d0f1ff;opacity: 0.49;"></div>
                    <div style="display: flex;justify-content: left;padding: 12px 16px 6px 16px">
                        <div style="width: 50%;font-size: 14px;color: #fff;">
                            <div style="width:60%;text-align:left;" data-v-fae5bece="">项目总数</div>
                            <div style="display: flex;justify-content: left;font-size: 18px;color: #ebf7ff;" ><span>288</span><span style="font-size:14px;color:#ffffff;" >个</span></div>
                        </div>
                        <div ><div style="width:100%;text-align:left;color: #ffffff" >申报工程项目</div><div style="display: flex;justify-content: left;font-size: 18px;color: #ebf7ff;" ><span >288</span><span style="font-size:14px;color:#ffffff;" >个</span></div></div>
                    </div>
                  </div>`
            }else {
              return `<div style="width: 316px;height: 190px;background: #013964">
      <div style="display: flex;justify-content: left;margin: 12px 0 10px 16px">
        <img style="width: 26px;height: 26px;vertical-align: middle"  src="@/assets/home/keyNoteLogo.png" alt="">
        <span style="font-size: 16px;color: #ffffff;vertical-align: middle">重点项目</span>
      </div>
      <div style="width: 100%;border: 1px solid rgba(195,216,225,0.56);"></div>
      <div style="display: flex;justify-content: space-between">
        <div style="margin: 12px 16px">
          <div style="text-align: left">
            <span style="font-size: 14px;color: #ffffff">项目名称：</span>
            <span style="font-size: 14px;color: #A2B5CA">这是项目名称这是项目名称</span>
          </div>
          <div style="text-align: left;margin-top: 12px">
            <span style="font-size: 14px;color: #ffffff">项目位置：</span>
            <span style="font-size: 14px;color: #A2B5CA">这是项目位置这是项目位置这是项</span>
          </div>
        </div>
        <img style="width: 100px;height: 100px;margin-top: 12px;margin-right: 16px" src="@/assets/home/profilePicture.png" alt="">
      </div>
    </div>`
            }
          }

          //return params.name + '<br/>' + '销售额：' + params.value
        },
      },
      geo: {
        type: "map",
        map: mapname,
        label: {
          // label 设置文本标签的显示格式，去掉不影响显示地图
          normal: {
            color: "#000000",
            show: true, //显示省份名称
          },
        },
        roam: true,
        itemStyle: {
          normal: {
            areaColor: 'rgba(0,0,0,0)',//默认区块颜色
            borderColor: '#03b8c0',//区块描边颜色
            // borderWidth:2,//区块描边颜色
            // opacity:0
          },
          emphasis: {
            areaColor: '#45ad00',//鼠标划过区块的颜色

            borderColor: '#03b8c0',//区块描边颜色
          },

        },
      },
      series: [
        {
          name: "在地图中显示散点图",
          type: "scatter",
          coordinateSystem: "geo", //设置坐标系为 geo
          data: ordinaryProject
        },
        {
          name: "在地图中显示散点图",
          type: "scatter",
          coordinateSystem: "geo", //设置坐标系为 geo
          // symbol: "circle",
          symbolSize: 25,
          data: provincialeKeyProject
        },
      ],
      // series: [
      //   {
      //     type: 'map',
      //     map: mapname,
      //   }
      // ]
    }
    // options.geo.map = mapname
    //options.series[0].map = mapname
    //然后重新绘制地图
    history.push(currentName)
    chart.setOption(optionsNew)
    currentName = name

  }

// function returnUpLevel() {
//   //先判断history有没有数据，能不能返回
//   if(history.length == 0){
//     return false
//   }
//   //取出要返回的那个名字
//   const name = history.pop()
//   const currentJson = echarts.getMap(mapname).geoJson
//   //修改地图配置重新绘制地图
//   options.geo.map = mapname
//   options.series[0].map = mapname
//   chart.setOption(options)
//   //修改当前的层级，名字
//   currentName = name
// }
// { name: "北京", value: [116.41995, 40.18994],
//     symbol:"image://" + require('@/assets/home/genericPoint.png'), // 自定义图片的 URL
//     symbolSize: [26,31]
// },

//重点项目数据

export default {
  components: {
    pageHeader,
    projectDetail,
    customSection,
    sectionEchartLine,
    sectionEchart3dBar,
    sectionEchartPie,
    sectionEchartLineBase,
    sectionEchartPieProgress,
    sectionKeyProject,
    ordinaryProject,//普通项目
    sectionListLg,
    sectionWarningList,
    sectionTopList,
    sectionElectricBox,
    projectInfo
  },
  data() {
    return {
      chinaData:[],
      keynoteData:[],
      ordinary:[],
      provincialeData:[],
      provincialeKeyProject:[],
      ordinaryProject:[],
      mapData:[],
      echartPieData:[],//项目数量
      options:[
        {
          value: '',
          label: '所有',
        },
        {
          value: 0,
          label: '前期',
        },
        {
          value: 1,
          label: '在建',
        },
        {
          value: 2,
          label: '竣工',
        },

      ],
      isShowProject:false,
      dialogDetail:false,
      projectListData:[],
      project:{
        name:'',
        projectStatus:'',
        pageSize:'2',
        pageNum:'1',
        isAsc:'asc',
        projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002',
        total:''
      },
      pageSizes: [2,4],
      pageSize: 2,
      currentPage: 1,
      total:4,
      projectId:'',
      isProjectDetail:false,
      recordListData:[],
      recordListDetailData:{}
    }
  },
  async mounted () {
    await this.echartPie().then(res=>{
      // console.log(res.data,'接口数据pie')
      this.echartPieData = res.data;

    })
  await this.chinaDataFn().then(res=>{
      // console.log(res.data,'接口数据')
      this.chinaData = res.data;
    })

    // console.log(this.chinaData,'this.chinaData2')
    for(let i = 0;i<=this.chinaData.length;i++){
      if(this.chinaData[i]?.basicMapTypeVos){
        // console.log(this.chinaData[i].basicMapTypeVos,'basicMapTypeVos')
        let obj = {
          name:this.chinaData[i].area,
          value:[this.chinaData[i].longitude,this.chinaData[i].latitude],
          projectNos:this.chinaData[i].projectNos,
        }
        for(let x = 0;x<=this.chinaData[i]?.basicMapTypeVos.length;x++){
          if(this.chinaData[i]?.basicMapTypeVos[x]) {
            // console.log(this.chinaData[i]?.basicMapTypeVos[x],'type')
            if(this.chinaData[i]?.basicMapTypeVos[x].type=='1') {
              obj.symbol = "image://" + require('@/assets/home/keyNote.png'),
              obj.symbolSize = [44, 79]
              obj.projects = this.chinaData[i]?.basicMapTypeVos[x].projects
              obj.type = this.chinaData[i]?.basicMapTypeVos[x].type
              this.keynoteData.push(obj)
              // console.log(JSON.stringify(this.keynoteData),'this.keynoteData')
            }else {
              obj.symbol = "image://" + require('@/assets/home/genericPoint.png')
              obj.symbolSize = [26,31]
              obj.basicLebVos = this.chinaData[i]?.basicMapTypeVos[x].basicLebVos
              obj.type = this.chinaData[i]?.basicMapTypeVos[x].type
              this.ordinary.push(obj)
              // console.log(this.ordinary,'this.ordinary')
              // console.log(JSON.stringify(this.ordinary),'ordinary')
            }
          }

        }
      }
    }
    // keynote(this.chinaData)
    echarts.registerMap('china', chinaMapJson)
    let myChart = echarts.init(this.$refs.chinaMap);
 
    var options = {
      backgroundColor: '',
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
       
          if(params.data) {
            if(params.data.type=='2'){
              return `<div style="width: 316px;height: 190px;background:#09325D;border: 1px solid #00cdff;">
                    <div style="margin-left: 12px;line-height: 38px;font-size: 16px;color: #fff;text-align: left;">一般项目</div>
                    <div style="width: 316px;height: 1px;border: 1px solid #d0f1ff;opacity: 0.49;"></div>
                    <div style="display: flex;justify-content: left;padding: 12px 16px 6px 16px">
                        <div style="width: 50%;font-size: 14px;color: #fff;">
                            <div style="width:60%;text-align:left;" data-v-fae5bece="">项目总数</div>
                            <div style="display: flex;justify-content: left;font-size: 18px;color: #ebf7ff;" ><span>288</span><span style="font-size:14px;color:#ffffff;" >个</span></div>
                        </div>
                        <div ><div style="width:100%;text-align:left;color: #ffffff" >申报工程项目</div><div style="display: flex;justify-content: left;font-size: 18px;color: #ebf7ff;" ><span >288</span><span style="font-size:14px;color:#ffffff;" >个</span></div></div>
                    </div>
                  </div>`
            }else {
              return `<div style="width: 316px;height: 190px;background: #013964">
      <div style="display: flex;justify-content: left;margin: 12px 0 10px 16px">
        <img style="width: 26px;height: 26px;vertical-align: middle"  src="${require('@/assets/home/keyNoteLogo.png')}" alt="">
        <span style="font-size: 16px;color: #ffffff;vertical-align: middle">重点项目</span>
      </div>
      <div style="width: 100%;border: 1px solid rgba(195,216,225,0.56);"></div>
      <div style="display: flex;justify-content: space-between">
        <div style="margin: 12px 16px;width: 60%">
          <div style="text-align: left;display: flex;flex-wrap: wrap">
            <span style="font-size: 14px;color: #ffffff">项目名称：</span>
            <span style="font-size: 14px;color: #A2B5CA">这是项目名称这这是项目名称这</span>
          </div>
          <div style="display: flex;flex-wrap: wrap;margin-top: 12px;">
            <span style="font-size: 14px;color: #ffffff">项目位置：</span>
            <span style="font-size: 14px;color: #A2B5CA">这是项目位置这是项目位置</span>
          </div>
        </div>
        <img style="width: 100px;height: 100px;margin-top: 12px;margin-right: 16px" src="${require('@/assets/home/profilePicture.png')}" alt="">
      </div>
    </div>`
            }
          }

          //return params.name + '<br/>' + '销售额：' + params.value
        },
      },
      geo: {
        type: "map",
        map: "china",
        label: {
          // label 设置文本标签的显示格式，去掉不影响显示地图
          normal: {
            color: "#000000",
            show: true, //显示省份名称
          },
        },
        roam: true,
        itemStyle: {
          normal: {
            areaColor: 'rgba(0,0,0,0)',//默认区块颜色
             borderColor: '#03b8c0',//区块描边颜色
            // borderWidth:2,//区块描边颜色
            // opacity:0
          },
          emphasis: {
            areaColor: '#45ad00',//鼠标划过区块的颜色

            borderColor: '#03b8c0',//区块描边颜色
          },

        },
        // regions: [ //这个地方是关键,通过设置regions,可以实现不同的省份边界线配置不同的颜色,
        //   {
        //     name: '安徽省', //这个名字要和china.js保持一致
        //     itemStyle: {
        //       normal: {
        //         areaColor: 'red',
        //         opacity:0
        //         // borderColor: "red",
        //         // borderWidth: 5,
        //         // background:'red',
        //       },
        //     },
        //   },
        //   {
        //     name: '北京市',
        //     itemStyle: {
        //       normal: {
        //         background:'red',
        //         borderColor: "red",
        //         borderWidth: 5,
        //         opacity:0
        //       },
        //     },
        //   }
        //
        // ],
      },

      series: [
        {
          name: "在地图中显示散点图",
          type: "scatter",
          coordinateSystem: "geo", //设置坐标系为 geo
          // symbol: "pin",
          symbolSize:25,
          itemStyle: {
            normal: {
              borderColor: '#AB4858', // 省份边界颜色
              areaColor: '#AB4858',   // 省份颜色
            },
            emphasis: {
              areaColor: '#0950A9' // 高亮时省份的颜色
            }
          },
          data:this.ordinary,

          // data: [
          //   //这里放标注点的坐标[{name: "北京",value: [116.46, 39.92]}]
          //   { name: "北京", value: [116.41995, 40.18994],
          //     symbol:"image://" + require('@/assets/home/genericPoint.png'), // 自定义图片的 URL
          //     symbolSize: [26,31]
          //   },

          // ],
        },
        {
          name: "在地图中显示散点图",
          type: "scatter",
          coordinateSystem: "geo", //设置坐标系为 geo
          // symbol: "circle",
          symbolSize: 25,
          data: this.keynoteData,
          itemStyle: {
            normal: {
              borderColor: '#AB4858', // 省份边界颜色
              areaColor: '#AB4858',   // 省份颜色
            },
            emphasis: {
              areaColor: '#0950A9' // 高亮时省份的颜色
            }
          },
        },
      ],
    }
    let chart = echarts.init(this.$refs.chinaMap);
    chart.setOption(options)
    chart.on('click', async (params) => {
      // console.log(params,'params12')
      this.mapData.push(params)
      if(this.mapData.length<2){
        let param = this.chinaData.filter((item) => {
          return item.area == params.name
        });
        // console.log(param,'param')

        let data = JSON.parse(JSON.stringify(param))
        //let data = params.name
        // console.log(data[0].projectNos,'params值')
        await this.provincialData(data[0].projectNos).then(res=>{
          // console.log(res.data,'省份接口数据')
          this.provincialeData = res.data;
        })
        for (let i = 0; i <= this.provincialeData.length; i++) {
          if (this.provincialeData[i]?.basicMapTypeVos) {
            console.log(this.provincialeData[i].basicMapTypeVos, '')
            let obj = {
              name: this.provincialeData[i].area,
              value: [this.provincialeData[i].longitude, this.provincialeData[i].latitude],
              projectNos: this.provincialeData[i].projectNos,
            }
            for (let x = 0; x <= this.provincialeData[i]?.basicMapTypeVos.length; x++) {
              if (this.provincialeData[i]?.basicMapTypeVos[x]) {
                console.log(this.provincialeData[i]?.basicMapTypeVos[x], 'type')
                if (this.provincialeData[i]?.basicMapTypeVos[x].type == '1') {
                  obj.symbol = "image://" + require('@/assets/home/keyNote.png'),
                      obj.symbolSize = [44, 79]
                  obj.projects = this.provincialeData[i]?.basicMapTypeVos[x].projects
                  obj.type = this.provincialeData[i]?.basicMapTypeVos[x].type
                  this.provincialeKeyProject.push(obj)
                  console.log(JSON.stringify(this.provincialeKeyProject), 'this.provincialeKeyProject')
                } else {
                  obj.symbol = "image://" + require('@/assets/home/genericPoint.png')
                  obj.symbolSize = [26, 31]
                  obj.basicLebVos = this.provincialeData[i]?.basicMapTypeVos[x].basicLebVos
                  obj.type = this.provincialeData[i]?.basicMapTypeVos[x].type
                  this.ordinaryProject.push(obj)

                  // console.log(this.ordinaryProject, 'this.Project')
                  // console.log(JSON.stringify(this.ordinaryProject), 'ordinaryProject')
                }
              }

            }
          }
        }
        //return
        if (params.componentType == 'geo') {
          goDown(params.name,chart,this.ordinaryProject,this.provincialeKeyProject)
          // console.log(params.componentSubType,'params')
          // console.log(params.name,'params2')
        }
      }else {
        this.goBack()
      }

    })

  },
  methods: {
    initTimer() {

    },
    goBackWork() {

     window.location.href="/"
    },
    //查看详情
    projectInfo(row){
      // alert(row.id)
      if(row.projectStatus == 0 || row.projectStatus == 3) {
        return
      }else {
        this.$router.push({ path: '/ProjectInformation', query: { projectId: row.id,type:row.type }});
      }
    },
    //项目详情按钮
    projectDetailFn(row){
      // console.log(row,"数据信息")
      this.projectId = row.id
      this.recordListDetailData = row
      this.isProjectDetail = true
      this.isShowProject = false
      this.recordList(this.project).then(res => {
        // console.log(res.data, '查询接口数据567')
        this.recordListData = res.data.rows
      })
    },
    async recordList (params){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/system/record/list',
        data: Qs.stringify(params)
      })
      return res
    },
    searchList(){
      // console.log(this.project,'this.project')
      this.projectListPage(this.project).then(res => {
        // console.log(res.data.rows, '查询接口数据')
        this.projectListData = res.data.rows;
        this.project.total = res.data.total
      })
      // let params={
      //   pageSize:'1',
      //   pageNum:'2',
      //   isAsc:'asc',
      //   name:this.project.name,
      //   projectStatus:this.project.state
      // }
    },
    async projectListPage (params){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/system/project/list',
        data: Qs.stringify(params)
      })
      return res
    },
    async projectListFn (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/system/project/list',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002',
          projectStatus:''
        })
      })
      return res
    },
    projectList(index){
      //总数 在建  申报 竣工
      if(index=='0') {
        this.projectListPage(this.project).then(res => {
          // console.log(res.data.rows, '查询接口数据')
          this.projectListData = res.data.rows;
          this.project.total = res.data.total
        })

      }else if(index=='1'){
        this.project.projectStatus = 1
        this.projectListPage(this.project).then(res => {
          // console.log(res.data.rows, '查询接口数据')
          this.projectListData = res.data.rows;
          this.project.total = res.data.total
        })
      }else if(index=='2'){
        this.project.projectStatus = 0
        this.projectListPage(this.project).then(res => {
          // console.log(res.data.rows, '查询接口数据')
          this.projectListData = res.data.rows;
          this.project.total = res.data.total
        })
      }else {
        this.project.projectStatus = 2
        this.projectListPage(this.project).then(res => {
          // console.log(res.data.rows, '查询接口数据')
          this.projectListData = res.data.rows;
          this.project.total = res.data.total
        })
      }
      this.dialogDetail = !this.dialogDetail
    },
    async echartPie (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/statistics/project/quantityStatistics',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002'
        })
      })
      return res
    },
    async provincialData(data){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/statistics/project/mapProjectStatistics',
        data: Qs.stringify({
          projectNos:data
        })
      })
      return res
    },
    async chinaDataFn (){

      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/statistics/project/mapProjectStatistics',
        data: Qs.stringify({
            projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002'
          })
      })
      return res
    },
    async init (){
      await this.chinaDataFn().then(res=>{
        // console.log(res.data,'接口数据')
        this.chinaData = res.data;
      })
      // console.log(this.chinaData,'this.chinaData2')
      for(let i = 0;i<=this.chinaData.length;i++){
        if(this.chinaData[i]?.basicMapTypeVos){
          // console.log(this.chinaData[i].basicMapTypeVos,'basicMapTypeVos')
          let obj = {
            name:this.chinaData[i].area,
            value:[this.chinaData[i].longitude,this.chinaData[i].latitude],
            projectNos:this.chinaData[i].projectNos,
          }
          for(let x = 0;x<=this.chinaData[i]?.basicMapTypeVos.length;x++){
            if(this.chinaData[i]?.basicMapTypeVos[x]) {
              // console.log(this.chinaData[i]?.basicMapTypeVos[x],'type')
              if(this.chinaData[i]?.basicMapTypeVos[x].type=='1') {
                obj.symbol = "image://" + require('@/assets/home/keyNote.png'),
                    obj.symbolSize = [44, 79]
                obj.projects = this.chinaData[i]?.basicMapTypeVos[x].projects
                obj.type = this.chinaData[i]?.basicMapTypeVos[x].type
                this.keynoteData.push(obj)
                // console.log(JSON.stringify(this.keynoteData),'this.keynoteData')
              }else {
                obj.symbol = "image://" + require('@/assets/home/genericPoint.png')
                obj.symbolSize = [26,31]
                obj.basicLebVos = this.chinaData[i]?.basicMapTypeVos[x].basicLebVos
                obj.type = this.chinaData[i]?.basicMapTypeVos[x].type
                this.ordinary.push(obj)
                // console.log(this.ordinary,'this.ordinary')
                // console.log(JSON.stringify(this.ordinary),'ordinary')
              }
            }

          }
        }
      }
      // keynote(this.chinaData)
      echarts.registerMap('china', chinaMapJson)
      let myChart = echarts.init(this.$refs.chinaMap);
      var options = {
        
        tooltip: {
          trigger: 'item',
          formatter: function(params) {
            // console.log(params.data,'params值')
            if(params.data) {
              if(params.data.type=='2'){
                return `<div style="width: 316px;height: 190px;background:#09325D;border: 1px solid #00cdff;">
                    <div style="margin-left: 12px;line-height: 38px;font-size: 16px;color: #fff;text-align: left;">一般项目</div>
                    <div style="width: 316px;height: 1px;border: 1px solid #d0f1ff;opacity: 0.49;"></div>
                    <div style="display: flex;justify-content: left;padding: 12px 16px 6px 16px">
                        <div style="width: 50%;font-size: 14px;color: #fff;">
                            <div style="width:60%;text-align:left;" data-v-fae5bece="">项目总数</div>
                            <div style="display: flex;justify-content: left;font-size: 18px;color: #ebf7ff;" ><span>288</span><span style="font-size:14px;color:#ffffff;" >个</span></div>
                        </div>
                        <div ><div style="width:100%;text-align:left;color: #ffffff" >申报工程项目</div><div style="display: flex;justify-content: left;font-size: 18px;color: #ebf7ff;" ><span >288</span><span style="font-size:14px;color:#ffffff;" >个</span></div></div>
                    </div>
                  </div>`
              }else {
                return `<div style="width: 316px;height: 190px;background: #013964">
                        <div style="display: flex;justify-content: left;margin: 12px 0 10px 16px">
                          <img style="width: 26px;height: 26px;vertical-align: middle"  src="@/assets/home/keyNoteLogo.png" alt="">
                          <span style="font-size: 16px;color: #ffffff;vertical-align: middle">重点项目</span>
                        </div>
                        <div style="width: 100%;border: 1px solid rgba(195,216,225,0.56);"></div>
                        <div style="display: flex;justify-content: space-between">
                          <div style="margin: 12px 16px">
                            <div style="text-align: left">
                              <span style="font-size: 14px;color: #ffffff">项目名称：</span>
                              <span style="font-size: 14px;color: #A2B5CA">这是项目名称这是项目名称</span>
                            </div>
                            <div style="text-align: left;margin-top: 12px">
                              <span style="font-size: 14px;color: #ffffff">项目位置：</span>
                              <span style="font-size: 14px;color: #A2B5CA">这是项目位置这是项目位置这是项</span>
                            </div>
                          </div>
                          <img style="width: 100px;height: 100px;margin-top: 12px;margin-right: 16px" src="@/assets/home/profilePicture.png" alt="">
                        </div>
                      </div>`
              }
            }

            //return params.name + '<br/>' + '销售额：' + params.value
          },
        },
        geo: {
          type: "map",
          map: "china",
          label: {
            // label 设置文本标签的显示格式，去掉不影响显示地图
            normal: {
              color: "#000000",
              show: true, //显示省份名称
            },
          },
        },
        series: [
          {
            name: "在地图中显示散点图",
            type: "scatter",
            coordinateSystem: "geo", //设置坐标系为 geo
            // symbol: "pin",
            symbolSize:25,
            // data: [{
            //   name:"安徽省",
            //   symbol:"image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAfCAYAAAD5h919AAAAAXNSR0IArs4c6QAABFFJREFUSEu9lktsFVUYx39nXvd9e/uwWmkCiARFlPKwGgn4KAZU3LgxkcSNIS40mKgbgyFF3RoIauLChWFhCHGDQXTRaGwbRa1I0FJRjFBphFLk9j7nzuuYmZbm3svc9tYFs5qZ7/H7vu/8z8wR3KRL3CQOTYHuuyT7LuhsVwSPSY8lAqQQXHQlA0s9jp/uFEMLFTwvaMWE3HRFlftzmrh/vkRpj6Eux3717O3GSCO/hqDbxuVLUwYHHYGyULW+XQen3eaFS93iUJh/KKjzgvvipK582Ayg2scfaWfFfe7ycu1wfewNoOVnrd6/4/q3DqiLBQWdSWkvzds95+6JnKkrojZd8g/nm4Kubvk/kOsxKds9ll+pPd0QdOdIcf251vhPoZDZ3hUppW/3hJh5EzxVXQJfynLVtHnHb+ti569bakaXHHP3FlRlX32g/6xK6aYtd0+X0PbnplFLSeuNbFR/cw5Yx8uYzuvZe/V3Q0Hx0/axkq49FRjrVi9TdvZl1+n91fnSP1sHcjH9lbAJRBzvo8oadVcoKHHSHi1GtNVhgSsuFm79c1tqstq2fKiw9q+2xKkw/7hpD5c2GJvDOxqxTpUMfW1Y4JLJ/KqJrenfq21dA7nN/3SmBsP8o6bzldmr94WC9B/sA7auhY4iVrY+KT8U2VmdNPZd5bNy1KhR13W7UXHeth7U94aC2r/I9l3tbBmoqXBOVZKIaR9N5kofSynUYia2qxI1tt2gulkpdkzlNkxtz5wMBfkv9UHrezui94aNo9l3esUesLcYj9epvja85eiVjbn2thNSUWa+DAt93+v2keJ5VnzyWk/hmY6xeUG+MXqsuNfMxGv3U5PtRLKF3ZUdqffq3RvWqx0vf+4kok82mT9wM/LlI9aO+LNhMY0Hc0QaasIcdGPRB5qBqSXza3ckupV+4S0O5Ht/MJkU3akvZTy6aT6YWjQH3PGLO9i9stLIb6Glhv5RQ6xe9qlMx0P2i0SdLh12xxI7G3XSUN6NKlIOZfd4meRbqOrMH9d1HSWbf817vvVgM6NduKPqLO9PrKe97R2QFlen9/By12gzkGZ2CRyRKt0YKOhEUCjPniFieMRwiWCzDAsRLoLw0Y3KJA634NGCQgqPKCoFVKaxKKEFmEgQnKdChBgaaRzaUNGQVFDJoZDFZoq1olgLOi1bkawBMlWjKCK5hkIPLisgAJxCCc4SNpIogruRlBGM4/AjGh1AYi6Hx7+4/MpGMT2zRr/IR/BIVUFstCDYRfAwHncBGgpDKJiBn9+t5NFZ0Bg2w+i0AK0zp6/Zyy+2RwzPgPyR2fg/vE7E3NetiOA8KlnKuGgkkcTRiSOROEEnJVIUMFGx6UDQPdeR7yO5zCRn2CaKtaoblQYVOoLKPNIIInh4gQTgWtChGtyDSyxYL38tBSnswM/EJY8kC0yxUdiL20f9UqEXnQwKiVnVFfHI4vFEoLj6s9ANqv8PIGeLL0g5mvoAAAAASUVORK5CYII=",
            //   symbolSize:[26, 31],
            //   value:['117.28304', '31.86119']
            // }
            // ]
            data:this.ordinary
            // data: [
            //   //这里放标注点的坐标[{name: "北京",value: [116.46, 39.92]}]
            //   { name: "北京", value: [116.41995, 40.18994],
            //     symbol:"image://" + require('@/assets/home/genericPoint.png'), // 自定义图片的 URL
            //     symbolSize: [26,31]
            //   },
            //   { name: "郑州", value: [113.665412, 34.757975],
            //     symbol:"image://" + require('@/assets/home/genericPoint.png'), // 自定义图片的 URL
            //     symbolSize: [26,31]
            //   },
            //   {
            //     name: "天津", value: [117.205126, 39.034933],
            //     symbol: "image://" + require('@/assets/home/genericPoint.png'), // 自定义图片的 URL
            //     symbolSize: [26, 31]
            //   },
            //   {
            //     name: "昆明", value: [102.81844, 24.906231],
            //     symbol: "image://" + require('@/assets/home/genericPoint.png'), // 自定义图片的 URL
            //     symbolSize: [26, 31]
            //   }
            //
            // ],
            //itemStyle:{color:''},symbol:'@/assets/images/box-bg.png'
          },
          {
            name: "在地图中显示散点图",
            type: "scatter",
            coordinateSystem: "geo", //设置坐标系为 geo
            // symbol: "circle",
            symbolSize: 25,
            data: this.keynoteData

            // data: [
            //   //这里放标注点的坐标[{name: "北京",value: [116.46, 39.92]}]
            //   { name: "广州", value: [113.26453, 23.155008],
            //     symbol:"image://" + require('@/assets/home/keyNote.png'), // 自定义图片的 URL
            //     symbolSize: [44, 79]
            //   },
            // ],
          },
        ],
        // series: [
        //   {
        //     type: 'map',
        //     map: 'china',
        //   }
        // ]
      }
      let chart = echarts.init(this.$refs.chinaMap);
      chart.setOption(options)
      chart.on('click', async (params) => {
        this.mapData.push(params)
        if(this.mapData.length<2){
          // console.log(params,'params12')
          let param = this.chinaData.filter((item) => {
            return item.area == params.name
          });
          // console.log(param,'param')

          let data = JSON.parse(JSON.stringify(param))
          //let data = params.name
          // console.log(data[0].projectNos,'params值')
          await this.provincialData(data[0].projectNos).then(res=>{
            // console.log(res.data,'省份接口数据')
            this.provincialeData = res.data;
          })
          for (let i = 0; i <= this.provincialeData.length; i++) {
            if (this.provincialeData[i]?.basicMapTypeVos) {
              // console.log(this.provincialeData[i].basicMapTypeVos, '')
              let obj = {
                name: this.provincialeData[i].area,
                value: [this.provincialeData[i].longitude, this.provincialeData[i].latitude],
                projectNos: this.provincialeData[i].projectNos,
              }
              for (let x = 0; x <= this.provincialeData[i]?.basicMapTypeVos.length; x++) {
                if (this.provincialeData[i]?.basicMapTypeVos[x]) {
                  // console.log(this.provincialeData[i]?.basicMapTypeVos[x], 'type')
                  if (this.provincialeData[i]?.basicMapTypeVos[x].type == '1') {
                    obj.symbol = "image://" + require('@/assets/home/keyNote.png'),
                        obj.symbolSize = [44, 79]
                    obj.projects = this.provincialeData[i]?.basicMapTypeVos[x].projects
                    obj.type = this.provincialeData[i]?.basicMapTypeVos[x].type
                    this.provincialeKeyProject.push(obj)
                    // console.log(JSON.stringify(this.provincialeKeyProject), 'this.provincialeKeyProject')
                  } else {
                    obj.symbol = "image://" + require('@/assets/home/genericPoint.png')
                    obj.symbolSize = [26, 31]
                    obj.basicLebVos = this.provincialeData[i]?.basicMapTypeVos[x].basicLebVos
                    obj.type = this.provincialeData[i]?.basicMapTypeVos[x].type
                    this.ordinaryProject.push(obj)

                    // console.log(this.ordinaryProject, 'this.Project')
                    // console.log(JSON.stringify(this.ordinaryProject), 'ordinaryProject')
                  }
                }

              }
            }
          }
          //return
          if (params.componentType == 'geo') {
            goDown(params.name,chart,this.ordinaryProject,this.provincialeKeyProject)
            // console.log(params.componentSubType,'params')
            // console.log(params.name,'params2')
          }
        }else {
          this.goBack()
        }

      })
    },
    goBack() {
      this.mapData = []
      this.init()
    },
    handleSizeChange(num){
      this.project.pageSize=num
      this.pageSize = num
      // console.log(this.project.pageSize,'this.project.pageNum')
      this.projectListPage(this.project).then(res => {
        // console.log(res.data.rows, '查询接口数据')
        this.projectListData = res.data.rows;
        this.project.total = res.data.total
      })
    },
    handleCurrentChange(num){
      this.project.pageNum = num
      this.currentPage = num
      // console.log(this.project.pageNum,'this.project.pageNum')
      this.projectListPage(this.project).then(res => {
        // console.log(res.data.rows, '查询接口数据123')
        this.projectListData = res.data.rows;
        this.project.total = res.data.total
      })

    },
  }
}
</script>
<style lang="stylus">
.page{
  width: 100%;
  height: 100%;
  background: url("@/assets/home/homeBj.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.page
  flex 1
  padding-top 107px
  position relative
  display flex
  flex-direction column
  min-height 100vh

  .pagerow
    flex 1

    .pagecol
      //display flex
      //flex-direction column
      flex-wrap nowrap
      &.bodycol
        justify-content space-between

      .projectMenu
        position relative
        padding-bottom 20px
        &:before
          position absolute
          z-index 0
          content ''
          left 50%
          bottom 0
          transform translateX(-50%)
          width 100vw
          height 100px
          background url(@/assets/images/bottombg.png) center bottom no-repeat
          background-size 90% auto
        .menuItem
          position relative
          z-index 10
          display inline-block
          margin 5px
          background url(@/assets/images/project-menu-bg.png) center center no-repeat
          background-size 100% 100%
          width 150px
          height 50px
          line-height @height
          font-family 'dinBold'
          font-size 14px
          color #fff
          text-decoration none
          text-shadow 0 1px 2px #006fe7
          &:hover
          &:active
          &.active
            background-image url(@/assets/images/project-menu-active-bg.png)
        @media (max-width 1024px)
          &:before
            background-size 150% auto

      .pageTotals
        padding 10px
        text-align center

        .totalItem
          display inline-block
          margin 10px 10px
          border-radius 6px
          border-width 1px
          border-style solid
          border-left-image-source linear-gradient(to bottom, #275a85 20%, #8cd6fe 80%)
          border-right-image-source linear-gradient(to bottom, #275a85 20%, #8cd6fe 80%)
          border-left-image-slice 1
          border-right-image-slice 1
          border-left-color #275a85
          border-right-color #275a85
          border-top-color #275a85
          border-bottom-color #8cd6fe
          padding 15px 15px 15px 65px
          position relative
          cursor pointer


          &:hover
          &:active
            background rgba(39, 90, 133, 0.4)

          img
            display block
            width 45px
            height auto
            position absolute
            left 15px
            top 50%
            transform translateY(-50%)

          .itemText
            padding-left 10px
            color #fff
            font-size 11px
            text-shadow 0 0 2px rgba(0, 0, 0, 0.3)
            text-overflow ellipsis
            white-space nowrap

            strong
              display block
              font-family 'dinBold'
              font-size 20px
              text-align left
              text-shadow 0 0 8px #006fe7

.home_con {
  width: 316px;
  height: 190px;
  background: linear-gradient( , rgba(255,0,0,0) 0%, rgba(255,0,0,0) 100%), rgba(1,57,100,0.51);
  border: 1px solid #00CDFF;
  backdrop-filter: blur(4px);
  //display: flex;
  //justify-content: left;
}
.home_con_title {
  margin-left: 12px;
  line-height: 38px;
  font-size: 16px;
  color: #FFFFFF;
  text-align:left;
}
.home_solid {
  width: 316px;
  height: 1px;
  border: 1px solid #D0F1FF;
  opacity: 0.49;
}
.homeInfo {

  display: flex;
  justify-content:left;
  padding: 12px 16px 6px 16px;
}
.home_total {
  width: 50%;
  font-size: 14px;
  color: #FFFFFF;

}
.home_number {
  display: flex;
  justify-content: left;
  font-size: 18px;
  color: #EBF7FF;
}
.homeMap {
  position: absolute;
  top:600px;
  left:260px
}
.project_title img{
  vertical-align: middle;
}
.project_title span{
  vertical-align: middle;
}
.monthMap {
  width: 450px;
  height: 260px;
  margin-bottom: 24px;
  background:url('@/assets/images/section-bg.png') no-repeat;
  background-size: 100% 100%;
}
.projectCon {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.9;
  z-index: 999;
}
.projectContain {
  width: 1600px;
  border: 2px solid;
  border-image: linear-gradient(180deg, rgba(92, 169, 255, 1), rgba(92, 169, 255, 0.14), rgba(92, 169, 255, 1)) 2 2;
}
.projectContain_title {
  width: 100%;
  height: 65px;
  background: url("@/assets/home/projectTop.png") no-repeat;
  background-size: 100% 100%;
  font-size: 34px;
  color: #FFFFFF;
  line-height: 65px;
  position: relative;
}
.project_close {
  font-size: 34px;
  color:#D8D8D8;
  position:absolute;
  right: 35px;
}
.tablewrapper {
  margin: 0 32px;
}
.select {
  width: 240px;
  height: 40px;
  border: 1px solid #4c4d4f;
  border-radius: 3px;
  background: transparent;
}
.select:focus {
  outline: none; /* 移除聚焦轮廓 */
  border-color: #409eff; /* 聚焦时边框颜色 */
  border-radius: 3px;
}

.select option {
  height: 40px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  font-weight: normal;
  color: #333;
}
.searchBtn {
  width: 48px;
  height: 40px;
  background: #3482CD;
  border-radius: 4px;
  color: #ffffff;
  border: 1px solid #3482cd;
}
.map_con {
  display: flex;
  justify-content: center;
}
.map_china {
  width:96px;
  height: 46px;
  line-height: 46px;
  background:url("@/assets/home/china.png") no-repeat;
  background-size: 100% 100%;
}
.map_shanghai {
  margin-left: 16px;
  width:96px;
  height: 46px;
  line-height: 46px;
  background:url("@/assets/home/shanghai.png") no-repeat;
  background-size: 100% 100%;
}
</style>