<template>
  <customSection :title="title" :isWarning="true" :canTitleClick="true" :overflow="false">
    <div class="tablewrapper">
      <el-table :data="tableData" stripe size="small" class="dataTable" height="250" style="width: 100%">
        <el-table-column prop="name" label="项目名称"/>
        <el-table-column prop="subname" label="分行名称"/>
        <el-table-column prop="statusText" align="center" label="项目状态" show-overflow-tooltip/>
        <el-table-column prop="day" align="center" label="节点停留天数" show-overflow-tooltip/>
      </el-table>
    </div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";

export default {
  data() {
    return {
      tableData: []
    }
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let that = this;
    that.init();
  },
  computed: {},
  methods: {
    init() {
      let that = this;
      that.tableData = [
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
      ];
    }
  }
}
</script>
<style scoped lang="stylus">
.tablewrapper
  padding 20px

  :deep(.el-table thead th)
    color #e38e96
    font-weight 400 !important

  :deep(.el-table__row)
    background-color transparent !important
    background-image linear-gradient(to right, rgba(107, 101, 136, 0.3), #3f2a43)

  :deep(.el-table__row--striped)
    background-color transparent !important
    background-image linear-gradient(to right, rgba(111, 82, 112, 0.3), #43182e)

  :deep(.el-table__cell)
    background-color transparent !important

  :deep(.el-table thead tr)
    background-color transparent !important

  :deep(.el-table th)
    border-color rgba(255, 255, 255, 0, 1) !important

  :deep(.el-table)
    tr
      td
        border-color rgba(255, 255, 255, 0, 1) !important
        color #ffc4c4

      &:hover
        td
          background-color rgba(0, 0, 0, 0.4) !important

  :deep(.el-table__inner-wrapper::before)
    background-color transparent !important
</style>