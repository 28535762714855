import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {},
    siteTitle: '',
    userInit: false,
  },
  getters: {
    uid: state => state.userInfo ? (state.userInfo.id || state.userInfo.uid || 0) : 0,
    name: state => state.userInfo ? state.userInfo.nickname : '',
    userInfo: state => state.userInfo ? state.userInfo :  {},
    userInit: state => state.userInit,
    siteTitle: state => state.siteTitle,
    hasLogined: state => state.userInit && state.userInfo && state.userInfo.id ? true : false,
  },
  mutations: {
    userLogin(state, userinfo) {
      state.userInfo = userinfo;
      state.userInit = true;
    },
    userLogout(state) {
      state.userInfo = {};
      state.userInit = false;
    }
  },
  actions: {
    login(context, userinfo) {
      context.commit('userLogin', userinfo);
    },
    logout(context) {
      context.commit('userLogout');
    }
  },
  modules: {},
})
