<template>
    <div>
        <el-form :inline="true" class="searchForm" style="text-align: left;margin:40px 0 30px 32px">
            <el-form-item size="small">
                <div class="flex gap-4 items-center">
                    <el-input style="width: 240px;height:30px" size="large" placeholder="搜索文件名称"
                        v-model="desigDrawingsParams.appendixName" />
                </div>
            </el-form-item>
            <el-form-item size="small">
                <el-form-item size="small">
                    <button @click="btnGo" type="button" class="searchBtn">查询</button>
                </el-form-item>
            </el-form-item>
        </el-form>

        <div class="tablewrapper">
            <el-table :data="projectListData" stripe size="large" class="homeTable" height="60vh" style="width: 100%">
                <el-table-column prop="projectNo" label="项目序号">
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.projectNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="项目名称" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="companyName" label="分行名称" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="projectNode" align="center" label="项目节点" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.projectNode }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="projectAddress" align="center" label="项目位置" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.projectAddress }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="projectStatus" align="center" label="项目状态" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)" v-if="row.projectStatus == 0">前期</span>
                        <span @click="projectDetailFn(row)" v-if="row.projectStatus == 1">在建</span>
                        <span @click="projectDetailFn(row)" v-if="row.projectStatus == 2">竣工</span>
                        <span @click="projectDetailFn(row)" v-if="row.projectStatus == 3">销项</span>
                        <span @click="projectDetailFn(row)" v-if="row.projectStatus == 4">停工</span>
                        <!--                <span @click="projectDetailFn(row)">{{row.projectStatus}}</span>-->
                    </template>
                </el-table-column>
                <el-table-column prop="changeTime" align="center" label="项目节点开始时间" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.changeTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="apartDay" align="center" label="项目节点停留时间" show-overflow-tooltip>
                    <template #default="{ row }">
                        <span @click="projectDetailFn(row)">{{ row.apartDay }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template #default="{ row }">
                        <span @click="projectInfo(row)">查看详情</span>
                    </template>
                </el-table-column>

            </el-table>
        </div>
        <!--底部分页 -->
        <div class="demo-pagination-block">
            <!-- <div class="demonstration"></div> -->
            <el-pagination v-model:currentPage="pageNum" v-model:page-size="pageSize" :page-sizes="[10, 20, 30, 40]"
                :small="small" :disabled="disabled" :background="background"
                layout="  prev, pager, next,sizes, jumper,total" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script>
import { projectListPage } from '@/api/projectInfo'
export default {
    props: {},
    data() {
        return {
            id: 1,//项目id
            desigDrawingsParams: { //设计图纸查询列表
                pageSize: '2',
                pageNum: '1',
                isAsc: 'asc',
                projectId: '1',
                total: 0,
                appendixName: '',//附件名称
            },
            projectListData: [],
            pageSize: 10,
            pageNum: 1,
            total: 40,
        }
    },
    components: {},
    mounted() {
        console.log(this.props, '详细信息')
        this.id = localStorage.getItem('projectId')
        console.log('设计图纸的页面', this.id);
        let that = this;
        that.getDesginList()
    },
    computed: {},
    methods: {
        // 获取图纸设计
        getDesginList() {
            console.log('设计图纸', this.id);
            projectListPage({
                name: '',
                projectStatus: '',
                pageSize: '2',
                pageNum: '1',
                isAsc: 'asc',
                projectNos: 'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002',
                total: '',
            }).then(res => {
                console.log('res', res);
                this.total = res.total
                this.projectListData = [...res.rows]
            })
        },
        //设计图纸显示按钮
        btnGo() {
            this.desigDrawings(this.desigDrawingsParams).then(res => {
                console.log(res.data, '设计')
                this.designDrawingsData = res.data.rows
                this.desigDrawingsParams.total = res.data.total;
            })
        },
            //查看详情
    projectInfo(row){
      // alert(row.id)
      if(row.projectStatus == 0 || row.projectStatus == 3) {
        return
      }else {
        this.$router.push({ path: '/ProjectInformation', query: { projectId: row.id,type:row.type }});
      }
    },
        handleSizeChange(val) {
            //alert('1')
            this.pageSize = val
            this.getDesginList()
            // console.log(`${val} items per page`)
        },
        handleCurrentChange(val) {
            this.current = val
            this.getDesginList()
            // console.log(`current page: ${val}`)
        },
    }
}
</script>
<style scoped>
.searchBtn {
    width: 48px;
    height: 30px;
}
</style>