import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Util from "@/utils/index";
import Request from "@/utils/request";
import Config from "@/config";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './assets/style/index.css'
import './assets/icon/customIcon.css'
import scroll from 'vue-seamless-scroll'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
const app = createApp(App)
app.use(scroll)
app.use(store)
app.use(router)
app.use(ElementPlus, {
    locale: zhCn,
})
app.config.globalProperties.$util = Util
app.config.globalProperties.$request = Request
app.config.globalProperties.$config = Config
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount("#app");
