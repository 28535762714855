<template>
  <customSection :title="title" class="progress" :overflow="false">
    <div class="echartPie" ref="echartPie"></div>
    <div class="total">全部设备 120</div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";

export default {
  data() {
    return {}
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let that = this;
    that.init();
  },
  computed: {},
  methods: {
    init() {
      let that = this;
      let chart = echarts.init(that.$refs.echartPie, "dark");


      var option = {
        backgroundColor: 'transparent',
        title: {
          text: '88.88%',
          subtext: '设备使用率',
          left: 'center',
          top: '35%',
          textStyle: {
            color: '#fff',
            fontSize: '24px'
          },
          subtextStyle: {
            color: '#fff',
            fontSize: '16px'
          }
        },
        series: [{
          name: '姓名',
          type: 'pie',
          radius: ['65%', '80%'],
          center: ['50%', '50%'],
          padAngle: 5,
          data: [{name: '设备在线', value: 10}, {name: '设备离线', value: 2}],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
            normal: {
              borderRadius: 30,
              color: function (params) {
                var colorList = [
                  {
                    c1: '#92afd7',
                    c2: '#0387f3'
                  },
                  {
                    c1: '#ffd2d2',
                    c2: '#ff3334',
                  }]
                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
                  offset: 0,
                  color: colorList[params.dataIndex].c1
                }, {
                  offset: 1,
                  color: colorList[params.dataIndex].c2
                }])
              }
            }
          }

        }
        ]
      };

      chart.setOption(option)
      window.onresize = function () {
        chart.resize();
      };
    }
  }
}
</script>
<style scoped lang="stylus">
.progress
  position relative

  .total
    position absolute
    left 0
    bottom 25px
    background-image linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 100%)
    font-size 14px
    line-height 30px
    padding 0 25px
    color #fff

  .echartPie
    display block
    width 100%
    height 25vh
    padding 15px
</style>