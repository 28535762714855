import request from '@/utils/request.js'
// 设计图纸接口
export function desginList(data) {
    return request({
        url: '/system/drawings/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

// 招标文件
export function bidList(data) {
    return request({
        url: '/system/document/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

// 危险源分析
export function warnList(data) {
    return request({
        url: '/system/dangerousSource/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

// 专项方案
export function specialList(data) {
    return request({
        url: '/system/program/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

// 阶段性验收报告
export function acceptance(data) {
    return request({
        url: '/system/acceptance/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

// 竣工报告
export function finishDate(data) {
    return request({
        url: '/system/report/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}


// 监控告警
export function minotorDate(data) {
    return request({
        url: '/system/alarm/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}

// 整改记录
export function rectificationRecords(data) {
    return request({
        url: '/system/rectificationRecords/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}
// 项目明细
export function projectListPage(data) {
    return request({
        url: '/system/project/list',
        method: 'post',
        data,
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
}