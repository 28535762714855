<template>
  <customSection :title="title" :canTitleClick="true" :overflow="false" @titleClick="titleClick">
    <div class="tablewrapper">
      <el-table :data="tableData" stripe size="small" class="dataTable" height="160" style="width: 100%;overflow-y:auto">
        <el-table-column prop="name" label="项目名称" width="148"/>
        <el-table-column prop="companyName" label="分行名称" width="64"/>
        <el-table-column prop="projectStatus" align="center" label="项目状态" width="64">
          <template #default="{row}">
            <!--            {{row.projectStatus}}-->
            <span v-if="row.projectStatus==0">前期</span>
            <span v-if="row.projectStatus==1">在建</span>
            <span v-if="row.projectStatus==2">竣工</span>
            <span v-if="row.projectStatus==3">销项</span>
            <span v-if="row.projectStatus==4">停工</span>
          </template>
        </el-table-column>
        <el-table-column prop="apartDay" align="center" label="节点停留天数" show-overflow-tooltip/>
      </el-table>
    </div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";
import axios from "axios";
import Qs from "qs";

export default {
  data() {
    return {
      tableData: []
    }
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let that = this;
    that.keyProject().then(res=>{
      console.log(res.data.rows,'接口数据rows123')
      this.tableData = res.data.rows;
    })
    that.init();
  },
  computed: {},
  methods: {
    titleClick(){
      let that = this;
      console.log('===== titleClick =====', 1)
    },
    async keyProject (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/system/project/list',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002',
          type:2
        })
      })
      return res
    },
    init() {
      let that = this;
      that.tableData = [
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
        {
          name: '项目名称……',
          subname: '分行名称',
          statusText: '停工',
          day: 2
        },
      ];
    }
  }
}
</script>
<style scoped lang="stylus">
.tablewrapper
  //padding 20px
  :deep(.el-table thead th)
    font-weight 400 !important

  :deep(.el-table__row)
    background-color transparent !important
    background-image linear-gradient(to right, rgba(6, 29, 61, 0.6), #061d3d)

  :deep(.el-table__row--striped)
    background-color transparent !important
    background-image linear-gradient(to right, rgba(22, 62, 111, 0.6), #061d3d)

  :deep(.el-table__cell)
    background-color transparent !important

  :deep(.el-table thead tr)
    background-color transparent !important

  :deep(.el-table th)
    border-color rgba(255, 255, 255, 0, 1) !important

  :deep(.el-table)
    tr
      td
        border-color rgba(255, 255, 255, 0, 1) !important

      &:hover
        td
          background-color rgba(0, 0, 0, 0.4) !important

  :deep(.el-table__inner-wrapper::before)
    background-color transparent !important
</style>