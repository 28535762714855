import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'

const routes = [
   {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/monitor',
    name: 'monitor',
    component: () => import(/* webpackChunkName: "monitor" */ '../views/Monitor.vue')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  // },
  {
    path: '/Visualization', //现场可视化
    name: 'Visualization',
    component: () => import(/* webpackChunkName: "login" */ '../views/Visualization/index.vue')
  },
  {
    path: '/ProjectInformation', //项目信息
    name: 'ProjectInformation',
    component: () => import(/* webpackChunkName: "login" */ '../views/ProjectInformation/index.vue')
  },
  {
    path: '/ElectricalBox', //智慧电箱
    name: 'ElectricalBox',
    component: () => import(/* webpackChunkName: "login" */ '../views/ElectricalBox/index.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
