
import axios from 'axios';

// 创建axios实例
const service = axios.create({
    baseURL: 'http://101.42.39.213:8080/', // api的base_url
    timeout: 5000, // 请求超时时间
    headers: {
        'Content-Type': 'application/json'
    }
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 例如设置token
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        }
        return config;
    },
    error => {
        // 对请求错误做些什么
        console.error('请求出错了', error); // for debug
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        /**
         * code为非200是验证错误信息 example
         * const res = {
         *   code: 500,
         *   message: '错误信息'
         * }
         * return Promise.reject(new Error(res.message || 'Error'));
         */
        const res = response.data;
        // if (res.code !== 200) {
        //     // 可以在这里统一处理错误状态码
        //     return Promise.reject(new Error(res.message || 'Error'));
        // } else {
        //     return res;
        // }
        return res;
    },
    error => {
        console.error('响应错误', error); // for debug
        return Promise.reject(error);
    }
);

export default service;