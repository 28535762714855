<template>
  <div v-if="show" class="popupMask" :class="type" @click.stop.prevent="maskClick" :style="{background:maskBackgroundColor}">
    <transition :name="transitionName">
      <div v-show="state" class="popupContent" @click.stop.prevent>
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      state: false,
      duration: 300
    }
  },
  computed: {
    transitionName() {
      const that = this;
      const names = {
        center: 'fade',
        left: 'slide-left',
        right: 'slide-right',
        top: 'slide-top',
        bottom: 'slide-bottom',
      }
      return names[that.type] || 'fade';
    }
  },
  props: {
    //top顶部弹出, center居中弹出, bottom底部弹出,left左侧弹出, right右侧弹出
    type: {type: String, default: 'center'},
    maskClose: {type: Boolean, default: false},
    maskBackgroundColor: {type: String, default: 'rgba(0,0,0,0.7)'},
    borderRadius: {type: String, default: ''}
  },
  methods: {
    open() {
      let that = this;
      that.show = true;
      that.$nextTick(function () {
        that.state = true
      });
    },
    close() {
      let that = this;
      that.state = false;
      setTimeout(function () {
        that.show = false;
      }, that.duration);
    },
    maskClick(){
      let that = this;
      if(!that.maskClose){
        return false
      }
      that.close();
    }
  }
};
</script>

<style lang="stylus" scoped>
.popupMask
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  display flex
  flex-direction column
  flex-wrap wrap
  z-index 200


  &.center
    justify-content center
    align-items center

  &.left
    align-items flex-start
    justify-content stretch

    .popupContent
      height 100%

  &.right
    align-items flex-end
    justify-content stretch

    .popupContent
      height 100%

  &.top
    justify-content flex-start

    .popupContent
      width 100%

  &.bottom
    justify-content flex-end

    .popupContent
      width 100%

.slide-left-enter-active
.slide-right-enter-active
.slide-top-enter-active
.slide-bottom-enter-active
.fade-enter-active
  transition all 0.3s ease-in

.slide-left-leave-active
.slide-right-leave-active
.slide-top-leave-active
.slide-bottom-leave-active
.fade-leave-active
  transition all 0.3s ease-in

.slide-left-enter-from
.slide-left-leave-to
  transform translateX(-100%)
  opacity 0

.slide-right-enter-from
.slide-right-leave-to
  transform translateX(100%)
  opacity 0

.slide-top-enter-from
.slide-top-leave-to
  transform translateY(-100%)
  opacity 0

.slide-bottom-enter-from
.slide-bottom-leave-to
  transform translateY(100%)
  opacity 0

.fade-enter-from
.fade-leave-to
  transform scale(1.2)
  opacity 0
</style>