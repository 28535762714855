<template>
  <div class="header">
    <router-link to="/" class="logo hidden-sm-and-down">
      <img src="@/assets/images/logo.png" />
    </router-link>
    <div class="sitename">中信银行总部智慧监测中心</div>
    <div class="today hidden-sm-and-down">
      <span v-text="todayText"></span>
      <span v-text="weekDay"></span>
      <span v-text="timeNow"></span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      timeNow: ''
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  mounted() {
    let that = this;
    that.initTimer();
  },
  computed: {
    todayText() {
      let that = this;
      return that.$util.formatDatetime(new Date(), 'yyyy.MM.dd')
    },
    weekDay() {
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      return weeks[new Date().getDay()]
    }
  },
  methods: {
    initTimer() {
      let that = this;
      let intervalFun = function () {
        that.timeNow = that.$util.formatDatetime(new Date(), 'hh:mm:ss');
      };
      intervalFun();
      setInterval(function () {
        intervalFun();
      }, 1000);
    }
  }
}
</script>

<style lang="stylus" scoped>

.header
  position fixed
  left 0
  top 0
  right 0
  z-index 800
  height 107px

  background-image url(@/assets/images/headerbg.png)
  background-position center top
  background-repeat no-repeat
  background-size 100%  100%
  padding 0 15px
  text-align center
  display flex
  flex-direction row
  flex-wrap nowrap
  justify-content space-between
  .logo
    display block
    padding 7px 0;
    float left

    img
      display block
      height 36px

  .sitename
    position absolute
    display block
    left 50%
    transform translateX(-50%)
    line-height 86px
    font-family 'dinBold'
    font-size 32px
    color #fff
    text-shadow 0 1px 2px #006fe7
    @media(max-width 767px)
      left 0
      transform none
      right 0
      text-align center
      font-size 20px !important
  .today
    font-size 15px
    line-height 50px
    span
      margin-left 30px

</style>