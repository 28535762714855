<template>
  <div class="section" :class="{warning:isWarning==true}">
    <h3 class="title" @click.stop.prevent="titleClick" :style="titleStyle">
      <span :class="{more: canTitleClick==true}">{{ title }}</span>
    </h3>
<!--    //:style="contentStyle"-->
    <div class="content" :class="{overflow: overflow == true}" :style="contentStyle">
      <slot/>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    canTitleClick: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: Boolean,
      default: false
    },
    isWarning: {
      type: Boolean,
      default: false
    },
    contentHeight: {
      type: String,
      default: '25vh'
    }
  },
  mounted() {
    let that = this;
    that.init();
  },
  computed: {
    contentStyle() {
      let that = this;
      return that.overflow ? {
        height: that.contentHeight
      } : {};
    },
    titleStyle() {
      let that = this;
      return that.canTitleClick ? {cursor: 'pointer'} : {};
    }
  },
  methods: {
    init() {
      let that = this;
    },
    titleClick() {
      let that = this;
      if (!that.canTitleClick) {
        return;
      }
      that.$emit('titleClick');
    }
  }
}
</script>
<style scoped lang="stylus">
.section
  margin 0 25px 25px 25px
  background rgba(0, 133, 255, 0.1) url(@/assets/images/section-bg.png) center bottom no-repeat
  background-size 100% auto
  text-align left
  position relative
  z-index 100

  .title
    background url(@/assets/images/section-title.png) left top no-repeat
    background-size 100% 100%
    font-size 18px
    font-weight 600
    line-height 40px
    padding 0 20px
    color #fff
    text-shadow 0 1px 2px #006fe7

    span
      display inline-block
      background url(@/assets/images/section-title-bg.png) left center no-repeat
      background-size auto 45%
      padding-left 25px
      &.more
        padding-right 60px
        position relative

        &:after
          position absolute
          content ''
          right 0
          top 50%
          width 40px
          height 40px
          transform translateY(-50%)
          background url(@/assets/images/section-title-more.png) right center no-repeat
          background-size auto 80%

  &.warning
    background-color transparent
    background-image url(@/assets/images/section-warning-bg.png)

    .title
      background-image url(@/assets/images/section-warning-title.png)
      text-shadow 0 1px 2px #f00

      span
        background-image url(@/assets/images/section-warning-title-bg.png)
        &.more
          &:after
            background-image url(@/assets/images/section-warning-title-more.png)

  .content
    display block

    &.overflow
      overflow auto

      &::-webkit-scrollbar
        width 5px
        height 5px
        background-color transparent

      &::-webkit-scrollbar-track
        border-radius 5px

      &::-webkit-scrollbar-thumb
        border-radius 5px
        background-color #2f71e1
</style>