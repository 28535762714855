<template>
  <customSection :title="title" :overflow="false">
    <div class="echartPie" ref="echartPie"></div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";
import axios from 'axios'
import Qs from 'qs'

export default {
  data() {
    return {
      xData:[],
      yData:[],
      echartPieData:[],
      total:'',
      finishData:[],
      newTotal:[]
    }
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  async mounted() {
    let that = this;
    await that.echartPie().then(res=>{
      console.log(res.data,'接口数据123')
      this.echartPieData = res.data;
    })
    that.init();
  },
  computed: {},
  methods: {
    async echartPie (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/statistics/project/quantityStatistics',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002'
        })
      })
      return res
    },
    init() {
      let that = this;
      let chart = echarts.init(that.$refs.echartPie, "dark");


      var option = {
        backgroundColor: 'transparent',
        legend: {
          top: '0%',
          left: 'center',
          itemWidth: 10,
          itemHeight: 10,
        },
        title: {
          text:33+'%',
          subtext: '已完成总数',
          left: 'center',
          top: '35%',
          textStyle: {
            color: '#fff',
            fontSize: '24px'
          },
          subtextStyle: {
            color: '#fff',
            fontSize: '16px'
          }
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['55%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 0
            },
            label:{
              show: false,
              formatter: ''
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                formatter: ''
              }
            },
            data: this.echartPieData
            // data: [
            //   {value: 1048, name: '项目总数', itemStyle: {opacity: 0.3}},
            //   {value: 735, name: '在建数量', itemStyle: {opacity: 0.3}},
            //   {value: 580, name: '审报数量', itemStyle: {opacity: 0.3}},
            //   {value: 484, name: '已完成数量', itemStyle: {opacity: 0.3}},
            //
            // ]
          },
          {
            name: 'Access From',
            type: 'pie',
            radius: ['72%', '75%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            label:{
              show: false,
              formatter: ''
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                formatter: ''
              }
            },
            data: this.echartPieData
            // data: [
            //   {value: 1048, name: '项目总数', itemStyle: {opacity: 1}},
            //   {value: 735, name: '在建数量', itemStyle: {opacity: 1}},
            //   {value: 580, name: '审报数量', itemStyle: {opacity:1}},
            //   {value: 484, name: '已完成数量', itemStyle: {opacity:1}},
            //
            // ]
          }
        ]
      };

      chart.setOption(option)
      window.onresize = function () {
        chart.resize();
      };
    }
  }
}
</script>
<style scoped lang="stylus">
.echartPie
  display block
  width 100%
  height 25vh
  padding 15px
</style>