<template>
  <router-view />
</template>
<script>
</script>
<style lang="stylus">
@font-face
  font-family 'dinBold'
  src url('@/assets/font/DouyinSansBold.otf') format('opentype')


body
  margin 0
  padding 0
  background #000

*
  box-sizing border-box
  padding 0
  margin 0

$headerHeight = 107px
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #fff
.el-image-viewer__close
.el-image-viewer__actions
  background-color rgba(255, 255, 255, 0.3) !important
</style>