<template>
  <customSection :title="title" :overflow="false">
    <div class="echartLine" ref="echartLineBase"></div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";

export default {
  data() {
    return {}
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let that = this;
    that.init();
  },
  computed: {},
  methods: {
    init() {
      let that = this;
      let chart = echarts.init(that.$refs.echartLineBase, "dark");
      chart.setOption({
        backgroundColor: 'transparent',
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      })
      window.onresize = function () {
        chart.resize();
      };
    }
  }
}
</script>
<style scoped lang="stylus">
.echartLine
  display block
  width 100%
  height 20vh
  padding 15px
</style>