<template>
  <customSection :title="title" :overflow="false">
    <div class="echartLine" ref="echartLine"></div>
  </customSection>

</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";
import axios from 'axios'
import Qs from 'qs'

export default {
  data() {
    return {
      xData:[],
      yData:[]
    }
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  async mounted() {
    let that = this;
    await that.echartLine().then(res=>{
      console.log(res.data,'接口数据')
      //this.chinaData = res.data;
      for(let i = 0; i<res.data.length;i++){
        //that.xData.push(res.data[i].curYm)
        that.yData.push(res.data[i].value)
        that.xData.push(res.data[i].curYm+'月')
      }
    })
    that.init();
  },
  computed: {},
  methods: {
    async echartLine (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/statistics/project/quantityCurYM',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002'
        })
      })
      return res
    },
    init() {
      let that = this;
      let chart = echarts.init(that.$refs.echartLine, "dark");
      chart.setOption({
        color:'#ffffff',
        backgroundColor: 'transparent',
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#ffffff',
            interval: 0
          },
          // data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月', '9月', '10月', '11月', '12月']
          data:this.xData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data:this.yData,
             //data: [820, 932, 901, 934, 1290, 1330, 1320, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true,

            lineStyle: {
              color: '#68CBDB'
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:'#296F91'
                  // color: 'rgb(128, 255, 165,0.5)'
                },
                {
                  offset: 1,
                  color:'#144879'
                  // color: 'rgb(128, 255, 165, 0)'
                }
              ])
            },
          },

        ]
      })
      window.onresize = function () {
        chart.resize();
      };
    }
  }
}
</script>
<style scoped lang="stylus">
.echartLine
  display block
  width 100%
  height 20vh
  padding 15px
</style>