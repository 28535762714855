<template>
  <customSection :title="title" :overflow="true">
    <div class="toplist">
      <div class="listItem" v-for="(item,key) in listData" :key="key" :class="'top'+(key+1)">
        <div class="itemDetail">
          <span style="width: 30px;display: inline-block" class="number" v-text="key+1"></span>
          <div class="name" style="width: 260px"><span v-text="item.projectName"></span></div>
          <span class="numberText" v-text="'TOP'+(key+1)"></span>
        </div>
        <el-progress :percentage="item.accessCount" :stroke-width="2" :show-text="false"/>
      </div>
    </div>
  </customSection>
</template>
<script>
import * as echarts from "echarts";
import customSection from "@/components/customSection/customSection.vue";
import axios from "axios";
import Qs from "qs";

export default {
  data() {
    return {
      listData: []
    }
  },
  components: {customSection},
  props: {
    title: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let that = this;
    that.sectionTopList().then(res=>{
      console.log(res,'接口数据rows')
       this.listData = res.data.rows;
    })
    that.init();
  },
  computed: {},
  methods: {
    async sectionTopList (){
      const res = await  axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'post',
        url:'http://101.42.39.213:8080/system/activation/list',
        data: Qs.stringify({
          projectNos:'zxyh-hf-001,zxyh-bj-001,zxyh-hf-002,zxyh-wh-002'
        })
      })
      return res
    },
    init() {
      let that = this;
      that.listData = [
        {
          name: '项目名称Top1',
          value: 90,
        },
        {
          name: '项目名称Top2',
          value: 80,
        }, {
          name: '项目名称Top3',
          value: 70,
        }
      ];
    }
  }
}
</script>
<style scoped lang="stylus">
.toplist
  padding 10px 20px

  .listItem
    padding 10px 0
    display flex
    flex-direction column

    .itemDetail
      padding-bottom 5px
      display flex
      flex-direction row
      flex-wrap nowrap
      justify-content space-between

      .number
        font-size 14px
        font-weight 600
        height 30px
        width 30px
        line-height @height
        text-align center
        color #59bfff

      .numberText
        font-size 15px
        color #69ccff
        font-weight 500
        line-height 30px

      .name
        font-size 13px
        line-height 30px
        padding 0 10px
        flex 1
        span
          display block
          overflow hidden
          text-overflow ellipsis
          word-break keep-all
          white-space nowrap

    :deep(.el-progress-bar__inner)
      background-color none
      background-image linear-gradient(to right, rgba(97, 198, 255, 0.2), #61c6ff)


    &.top1
      .number
        background url(@/assets/images/top1.png) center center no-repeat
        background-size 100% 100%

      .number
      .numberText
        font-weight normal
        color #ffb100

      :deep(.el-progress-bar__inner)
        background-image linear-gradient(to right, rgba(255, 177, 0, 0.2), #ffb100)

    &.top2
      .number
        background url(@/assets/images/top2.png) center center no-repeat
        background-size 100% 100%

      .number
      .numberText
        color #fff

      :deep(.el-progress-bar__inner)
        background-image linear-gradient(to right, rgba(255, 255, 255, 0.2), #fff)

    &.top3
      .number
        background url(@/assets/images/top3.png) center center no-repeat
        background-size 100% 100%

      .number
      .numberText
        color #be966b

      :deep(.el-progress-bar__inner)
        background-image linear-gradient(to right, rgba(190, 150, 107, 0.2), #be966b)
</style>